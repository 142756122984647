import { graphql } from 'gatsby';
import React from 'react';
import { CardDeck } from 'reactstrap';
import {
  Button,
  HeroCarousel,
  Layout,
  MapContainer,
  SEO,
  ServiceCard,
  Testimonials,
  Wide,
} from '../components';
import './style.scss';

const IndexPage = ({ data }) => (
  <Layout subtitle={data.pageJson.subtitle}>
    <SEO
      title="DFW Residential & Commercial Real Etsate"
      type="website"
      corporation
    />
    <Wide className="hero-container">
      <HeroCarousel heroImages={data.allFile.edges} />
      <div className="hero-text">
        <h1 style={{ fontSize: 'calc(2em + 2vw)' }}>
          {data.pageJson.hero.heading}
        </h1>
        <p>{data.pageJson.hero.subheading}</p>
        <Button
          style={{
            margin: '0px 15px 15px 15px',
          }}
          text="Get in Touch"
          to="/contact/"
          role="button"
          primary
          translucent
        />
        <Button
          style={{
            margin: '0px 15px 15px 15px',
          }}
          to="/about/"
          text="About Us"
          role="button"
          translucent
        />
      </div>
    </Wide>
    <CardDeck style={{ margin: '0 10px 40px 10px', maxWidth: 960 }}>
      {data.pageJson.services.map(service => (
        <ServiceCard
          key={service.title}
          icon={service.icon}
          service={service.title}
          desc={service.desc}
        />
      ))}
    </CardDeck>
    <div
      style={{ display: 'flex', justifyContent: 'center', marginBottom: 50 }}
    >
      <Button text="Contact Us" to="/contact/" role="button" primary />
    </div>
    <Wide style={{ height: 450, marginBottom: 50 }}>
      <MapContainer />
    </Wide>
    <div style={{ marginBottom: '3vh' }}>
      <h2 style={{ textAlign: 'center' }}>Testimonials</h2>
      <Testimonials slides={data.pageJson.testimonials} />
    </div>
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/hero-index/" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1920, quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    pageJson(page: { eq: "index" }) {
      subtitle
      hero {
        heading
        subheading
      }
      testimonials {
        key
        statement
        name
      }
      services {
        icon
        title
        desc
      }
    }
  }
`;
